<template>
  <v-container class="pb-6">
    <v-row class="text-left">
      <!-- left column -->
      <v-col cols="12" sm="6" class="pa-0">
        <div class="overline">
          {{ assignmentInfo.assignmentDetails.assignmentTitle }}
        </div>
        <h2>{{ assignmentInfo.assignmentDetails.assignmentDetail }}</h2>
        <v-chip class="my-5">
          {{
            assignmentInfo.assignmentDetails.geo +
            (assignmentInfo.assignmentDetails.sourceGeo
              ? ` (${assignmentInfo.assignmentDetails.sourceGeo})`
              : "")
          }}
        </v-chip>
        <div>
          <!-- project -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Project</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.project?.name
            }}</v-col>
          </v-row>

          <!-- po -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Purchase Order</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.pricing?.purchaseOrder?.purchaseOrderNumber
            }}</v-col>
          </v-row>

          <!-- project manager -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Project Manager</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.projectManager
                ? `${assignmentInfo.projectManager?.firstName} ${assignmentInfo.projectManager?.lastName}`
                : ""
            }}</v-col>
          </v-row>

          <!-- media type -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Media Type</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.assignmentDetails.mediaType
            }}</v-col>
          </v-row>

          <!-- keyword -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Keyword</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.assignmentDetails.keyword
            }}</v-col>
          </v-row>

          <!-- Request Type -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Request Type</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.assignmentDetails.requestType
            }}</v-col>
          </v-row>

          <!-- File Location -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >File Location</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.assignmentDetails.fileLocation
            }}</v-col>
          </v-row>

          <!-- Request Date -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Request Date</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              formatDateTime(assignmentInfo.schedule.requestDate)
            }}</v-col>
          </v-row>

          <!-- pay period -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Pay Period</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.schedule.payPeriod
                ? `${$moment(assignmentInfo.schedule.payPeriod).format(
                    "MMMM YYYY"
                  )}`
                : ""
            }}</v-col>
          </v-row>

          <!-- Assignment ID -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Assignment ID</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.assignmentDetails?.assignmentId ? assignmentInfo.assignmentDetails?.assignmentId : null
            }}</v-col>
          </v-row>

          <!-- Task ID -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Task ID</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.task?.taskId
            }}</v-col>
          </v-row>
          
          <!-- Client Requester -->
          <v-row v-if="isAdmin || isManager">
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Client Requester</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              `${assignmentInfo.clientRequester?.firstName ?? ""} ${
                assignmentInfo.clientRequester?.lastName ?? ""
              }`
            }}</v-col>
          </v-row>

          <!-- word count -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Word Count</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.assignmentDetails.wordCount
            }}</v-col>
          </v-row>

          <!-- character count -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Character Count</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.assignmentDetails.characterCount
            }}</v-col>
          </v-row>

          <!-- created -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Created</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              `${formatDate(assignmentInfo.createdDate, true)} ${
                  assignmentInfo.createdBy
                    ? ` by ${assignmentInfo.createdBy.firstName} ${assignmentInfo.createdBy.lastName}`
                    : ""
                }`
            }}</v-col>
          </v-row>

          <!-- last updated -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Last Updated</v-col
            >
            <v-col class="py-1" cols="12" sm="6">{{
              (assignmentInfo.updatedBy || assignmentInfo.lastModifiedDate) && assignmentInfo.createdDate !== assignmentInfo.lastModifiedDate
                ? `${formatDate(assignmentInfo.lastModifiedDate, true)}
                ${
                  assignmentInfo.updatedBy
                    ? ` by ${assignmentInfo.updatedBy.firstName} ${assignmentInfo.updatedBy.lastName}`
                    : ""
                }`
                : ""
            }}</v-col>
          </v-row>

          <!-- document -->
          <v-row class="mx-0" v-if="assignmentInfo.document">
            <v-col
              cols="12"
              sm="3"
              class="py-1 px-0 font-weight-bold d-flex align-center"
              >Document</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              <v-btn
                color="accent"
                :loading="documentLoading"
                :disabled="!assignmentInfo.document"
                @click="downloadFile(assignmentInfo.document, 'document')"
              >
                Download<v-icon right>mdi-download</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!-- document -->
          <v-row class="mx-0" v-if="assignmentInfo.documents?.length">
            <v-col
              cols="12"
              sm="3"
              class="py-1 px-0 font-weight-bold d-flex align-center"
              >Documents</v-col
            >
            <v-col :class="{'second-btn': index > 0}" class="d-flex py-1 px-0" cols="12" sm="6" v-for="(file, index) in assignmentInfo.documents" :key="index">
              <v-btn
                class="download-button mr-2"
                color="accent"
                :loading="documentLoading"
                :disabled="!file"
                @click="downloadNewFile(file, assignmentInfo)"
              >
                <v-icon right>mdi-download</v-icon>
              </v-btn>
              <p class="mb-0 d-flex align-center" v-if="file.fileName">{{ file.fileName + '.' + file.fileExtension }}</p>
            </v-col>
          </v-row>

          <!-- link -->
          <v-row class="mx-0">
            <v-col
              cols="12"
              sm="3"
              class="py-1 px-0 font-weight-bold d-flex align-center"
              >Link</v-col
            >
            <v-col class="py-1 px-0" cols="12" sm="6">
              <v-btn
                color="accent"
                :href="formattedLink"
                target="_blank"
                :disabled="!assignmentInfo.assignmentDetails.link"
              >
                Open<v-icon right>mdi-open-in-new</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!-- note -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold">Note to Staff</v-col>
            <v-col class="py-1" cols="12" sm="6">{{
              assignmentInfo.assignmentDetails.note
            }}</v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="6">
        <div class="table-bg text-center">
          <h3 class="pb-2">Assignment Steps</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Step</th>
                  <th class="text-center">Person</th>
                  <th class="text-center">Due</th>
                  <th class="text-center">Accepted</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Primary</td>
                  <td>
                    {{
                      `${assignmentInfo.primary?.firstName ?? ""} ${
                        assignmentInfo.primary?.lastName ?? ""
                      }`
                    }}
                  </td>
                  <td>
                    {{
                      formatDateTime(
                        assignmentInfo.schedule.primaryDueDate,
                        assignmentInfo.schedule.primaryDueTime
                      )
                    }}
                  </td>
                  <td>
                    <v-icon
                      v-if="assignmentInfo.assignmentDetails.primaryAccepted"
                      >mdi-check</v-icon
                    >
                  </td>
                </tr>
                <tr v-if="assignmentInfo.secondary?.id">
                  <td>Secondary</td>
                  <td>
                    {{
                      `${assignmentInfo.secondary.firstName} ${assignmentInfo.secondary.lastName}`
                    }}
                  </td>
                  <td>
                    {{
                      formatDateTime(
                        assignmentInfo.schedule.secondaryDueDate,
                        assignmentInfo.schedule.secondaryDueTime
                      )
                    }}
                  </td>
                  <td>
                    <v-icon
                      v-if="assignmentInfo.assignmentDetails.secondaryAccepted"
                      >mdi-check</v-icon
                    >
                  </td>
                </tr>
                <tr v-if="assignmentInfo.qc?.id">
                  <td>QC</td>
                  <td>
                    {{
                      `${assignmentInfo.qc.firstName} ${assignmentInfo.qc.lastName}`
                    }}
                  </td>
                  <td>
                    {{
                      formatDateTime(
                        assignmentInfo.schedule.qcDueDate,
                        assignmentInfo.schedule.qcDueTime
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="mt-6 table-bg text-center">
          <h3 class="pb-2">Assignment Work Types</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Work Type</th>
                  <th class="text-center">Quantity</th>
                  <th v-if="isAdmin" class="text-center">Primary Rate</th>
                  <th v-if="isAdmin" class="text-center">Secondary Rate</th>
                  <th v-if="isAdmin" class="text-center">Client Rate</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(workType, i) in getCustomSortWorkTypes()" :key="i">
                  <td>
                    <span
                      :style="{
                        textDecoration: isAdmin ? 'underline' : 'none',
                      }"
                      @click="
                        checkPrice(
                          workType.customerPriceList?.workTypeCategory,
                          workType.customerPriceList?.workTypeName
                        )
                      "
                    >
                      {{ workType.customerPriceList?.workTypeName }}
                    </span>
                  </td>
                  <td>
                    {{ workType.quantity }}
                  </td>
                  <td v-if="isAdmin">
                    {{
                      `$${(
                        workType.customerPriceList?.primaryPriceCard ?? 0
                      ).toFixed(2)}`
                    }}
                  </td>
                  <td v-if="isAdmin">
                    {{
                      workType.customerPriceList?.secondaryPriceCard !== null
                        ? `$${(
                            workType.customerPriceList?.secondaryPriceCard ?? 0
                          ).toFixed(2)}`
                        : "-"
                    }}
                  </td>
                  <td v-if="isAdmin">
                    {{
                      workType.customerPriceList?.clientPriceCard !== null
                        ? `$${(
                            workType.customerPriceList?.clientPriceCard ?? 0
                          ).toFixed(2)}`
                        : "-"
                    }}
                  </td>
                </tr>
                <tr v-if="isAdmin">
                  <td class="text-right">TOTAL</td>
                  <td></td>
                  <td>
                    {{
                      `$${Number(assignmentInfo.pricing.primaryTotal).toFixed(
                        2
                      )}`
                    }}
                  </td>
                  <td>
                    {{
                      assignmentInfo.pricing.secondaryTotal !== null
                        ? `$${assignmentInfo.pricing.secondaryTotal.toFixed(2)}`
                        : "-"
                    }}
                  </td>
                  <td>
                    {{
                      assignmentInfo.pricing.clientTotal !== null
                        ? `$${assignmentInfo.pricing.clientTotal.toFixed(2)}`
                        : "-"
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div v-if="isAdmin || isManager" class="mt-6 table-bg text-center">
          <h3 class="pb-2">Client Flow</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Reviewed</th>
                  <th class="text-center">Feedback</th>
                  <th class="text-center">Billed</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <v-icon
                      v-if="assignmentInfo.assignmentDetails.clientReviewed"
                      >mdi-check</v-icon
                    >
                  </td>
                  <td>
                    <v-icon
                      v-if="assignmentInfo.assignmentDetails.clientFeedback"
                      >mdi-check</v-icon
                    >
                  </td>
                  <td>
                    <v-icon v-if="assignmentInfo.assignmentDetails.billed"
                      >mdi-check</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <v-row class="mt-3" v-if="isAdmin || isManager">
          <v-col cols="12" sm="3" class="py-1 font-weight-bold">Note to Managers</v-col>
          <v-col class="py-1" cols="12" sm="6">{{
            assignmentInfo.assignmentDetails?.noteToManager
          }}</v-col>
        </v-row>
        <v-row class="mt-3" v-if="isAdmin || isManager">
          <v-col cols="12" sm="3" class="py-1 font-weight-bold">Related Text</v-col>
          <v-col class="py-1" cols="12" sm="6">{{
            assignmentInfo.assignmentDetails?.relatedText
          }}</v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-end px-3" v-if="isAdmin || isManager">
      <!-- bottom controls -->
      <v-btn class="secondary" @click="handleEditForm">Edit</v-btn>
    </v-row>
  </v-container>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import { toTitleCase, getFile, getNewAssignmentFile } from "@/utils/newDbUtils";
import { DOMAIN, ROUTES } from "@/utils/constants";
const JSZip = require("jszip");

export default {
  name: "ADTableExpansion",
  props: {
    assignmentInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { documentLoading: false };
  },
  computed: {
    ...mapGetters("auth", ["isAdmin", "isManager"]),
    formattedLink() {
      const link = this.assignmentInfo.assignmentDetails.link;
      if (link && !link.startsWith('http://') && !link.startsWith('https://')) {
        return 'https://' + link;
      }
      return link;
    }
  },
  methods: {
    ...mapActions("assignment", [
      "setNewAssignmentDatas",
      "setNewAssignmentModalOpen",
    ]),
    formatDateTime(date, time) {
      return date
        ? `${this.$moment(date).format("MM-DD-YYYY")}${time ? " " + time : ""}`
        : "";
    },
    formatDate(date, withTime) {
      return date
        ? `${this.$moment(
            withTime
              ? new Date(date).toLocaleString("en-US", {
                  timeZone: "America/New_York",
                })
              : date
          ).format(`MM-DD-YYYY${withTime ? " hh:mm A" : ""}`)}`
        : "";
    },
    handleEditForm() {
      this.setNewAssignmentDatas([this.assignmentInfo]);
      this.setNewAssignmentModalOpen(true);
    },
    downloadFile(url, fileName) {
      this[`${fileName}Loading`] = true;
      getFile({ url }).then(async (resp) => {
        const base64Response = await fetch(
            `data:application/pdf;base64,${resp}`
          ),
          blob = await base64Response.blob(),
          blobName = `${this.assignmentInfo.assignmentDetails.assignmentDetail}_${
            this.assignmentInfo.primary.firstName + this.assignmentInfo.primary.lastName
          }_${this.formatDateTime(
            this.assignmentInfo.schedule.requestDate
          )}.pdf`;
        saveAs(blob, blobName);
        this[`${fileName}Loading`] = false;
      });
    },
    downloadNewFile(fileId, assignmentInfo) {
      this.documentLoading = true;
      if (fileId && fileId.id && fileId.fileName) {
        getNewAssignmentFile(fileId.id).then(async (resp) => {
          const base64Response = await fetch(`data:application/${resp.data.fileExtension};base64,${resp.data.content}`);
          const blob = await base64Response.blob();
          const blobName = `${this.assignmentInfo.assignmentDetails.assignmentDetail}_${
            this.assignmentInfo?.primary ?
            (this.assignmentInfo.primary.firstName + this.assignmentInfo.primary.lastName)
            : ""
          }_${this.formatDateTime(this.assignmentInfo.schedule.requestDate)}.${resp.data.fileExtension}`;
          saveAs(blob,
            assignmentInfo.assignmentDetails?.wordCount || assignmentInfo.assignmentDetails?.wordCount === 0
            ? `${resp.data.fileName}.${resp.data.fileExtension}`
            : blobName);
          this.documentLoading = false;
        });
      } else if (!fileId.fileName) {
        getNewAssignmentFile(fileId.id).then(async (resp) => {
          const fileType = await this.getFileTypeFromData(resp.data);
          const base64Response = await fetch(`data:application/${fileType};base64,${resp.data.content}`);
          const blob = await base64Response.blob();
          const blobName = `${this.assignmentInfo.assignmentDetails.assignmentDetail}_${
            this.assignmentInfo.primary.firstName + this.assignmentInfo.primary.lastName
          }_${this.formatDateTime(this.assignmentInfo.schedule.requestDate)}.${fileType}`;
          saveAs(blob, blobName);
          this.documentLoading = false;
        });
      }
    },
    async getFileTypeFromData(data) {
      if (data.content.startsWith('JVBERi0')) return 'pdf';
      if (data.content.startsWith('/9j/')) return 'jpeg';
      if (data.content.startsWith('iVBORw0KGgo')) return 'png';
      const uint8Array = this.base64ToUint8Array(data.content);
      try {
        const zip = await JSZip.loadAsync(uint8Array);
        if (zip.file('word/document.xml') || zip.file('word/document2.xml')) {
          return 'docx';
        }
        if (zip.file('xl/workbook.xml')) {
          return 'xlsx';
        }
        return 'octet-stream';
      } catch (err) {
        console.error('Error loading ZIP file:', err);
        return 'octet-stream';
      }
    },
    base64ToUint8Array(base64) {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes;
    },
    checkPrice(workTypeCategory, workTypeName) {
      if (this.isAdmin)
        window.open(
          `${DOMAIN}/${ROUTES.customerPriceList}?WorkTypeCategory=${workTypeCategory}&Search=${workTypeName}`,
          "_blank"
        );
    },
    getCustomSortWorkTypes() {
      if (
        this.assignmentInfo.project?.name === "iTunes FC" &&
        this.assignmentInfo.assignmentDetails.requestType === "Copywriting"
      ) {
        const getIndex = (wt) => {
          if (
            wt === "CWR_500-60-1100" ||
            wt === "CWR_2500-00-3800" ||
            wt === "CWR_2500-00-4500" ||
            wt === "CWR_3500-00-4500" ||
            wt === "CWR_1000-00-00"
          )
            return 0;
          else if (wt === "CWR_1000-120-1500") return 1;
          else if (wt === "CWR_1500-180-2800") return 2;
          else if (
            wt === "CWR_1000-380-3800" ||
            wt === "CWR_1500-380-3800" ||
            wt === "CWR_2500-300-3800" ||
            wt === "CWR_2000-500-3800"
          )
            return 3;
          else if (wt === "CWR_5000-500-7000") return 4;
          else if (wt === "CWR_300-100-500" || wt === "CWR_500-00-500")
            return 5;
          else return 6;
        };
        return this.assignmentInfo.pricing.workTypes
          ?.map((el) => ({
            ...el,
            index: getIndex(el.customerPriceList.workTypeName),
          }))
          ?.sort((a, b) => a.index - b.index);
      } else return this.assignmentInfo.pricing.workTypes;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
.second-btn {
  margin-left: 150px;
}
.download-button {
  padding: 8px !important;
  span {
    max-width: 32px !important;
    i {
      margin: 0 !important;
    }
  }
}

@media screen and (max-width: 1264px) {
  .second-btn {
    margin-left: 113px;
  }
}
</style>
